/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Debounce} from '../../../common/utils/utils';
import LayoutStore from '../../../layout/layout-store';
import Preloader from '../../../preloader';
import ListTitles from '../../../titles/browse/list-titles';
import FilterOptions from '../../../titles/filter-options/filter-options';
import {TitleActions} from '../../../titles/title-actions';
import TitleStore from '../../../titles/title-store';
import EventStore from '../../event-store';

class AddExistingTitles extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            onAddTitle: PropTypes.func.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        const ts = TitleStore.getState();
        const es = EventStore.getState();
        return {
            filters: ts.get('filters'),
            offset: ts.get('offset'),
            previouslySelectedTitles: es.getIn(['event', 'titles']),
            selectedTitles: es.get('selectedTitles'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            size: ts.get('size'),
            sortDirection: ts.get('sortDirection'),
            sortFieldName: ts.get('sortFieldName'),
            titles: ts.get('titles'),
            total: ts.get('total'),
            showPreloader: es.get('showPreloader'),
        };
    }

    static getStores() {
        return [TitleStore, LayoutStore, EventStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            selectedTitle: Immutable.Map()
        }, this.constructor.calculateState());

        this.handleAddTitle = this.handleAddTitle.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        TitleActions.clear();
        TitleActions.get(Immutable.fromJS({}), this.state.offset, this.state.size);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.titles !== this.state.titles ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel;
    }

    handleAddTitle(event) {
        if (~event.target.className.indexOf('add-title-panel') ||
            ~event.target.className.indexOf('fa-plus')) {
            let titleId = parseInt(event.target.getAttribute('data-title-id'));

            if (~event.target.className.indexOf('fa-plus')) {
                titleId = parseInt(event.target.parentNode.getAttribute('data-title-id'));
            }

            const titleToAdd = this.state.titles.find(title => title.get('id') === titleId);
            this.props.onAddTitle(titleToAdd);
        }

        return;
    }

    handleLoadPage(pageNumber) {
        TitleActions.get(this.state.filters, pageNumber * this.state.size, this.state.size);
        return;
    }

    handleSearchTerm(term) {
        TitleActions.setFilter('title', term);
        TitleActions.setFilter('operator', 'AND');
        TitleActions.get(this.state.filters, 0, this.state.size);
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        TitleActions.setSort(sortFieldName, sortDirection);
        TitleActions.get(this.state.filters, 0, this.state.size, this.state.sortFieldName, this.state.sortDirection);
        return;
    }

    render() {
        const {titles, total, offset, size} = this.state;
        /*istanbul ignore next*/
        let columns = ['ro', 'parentTitle', 'title', 'categoryName', 'mpmNumber', 'titleReleaseDate',
            {
                name: 'actions',
                get: (title, props, context) => {
                    let buttonLabel, buttonStyle, buttonIcon;

                    if (this.state.selectedTitles.findIndex(t => t.get('titleId') === title.get('id')) === -1) {
                        buttonLabel = 'select-title';
                        buttonStyle = 'btn-primary-outline';
                        buttonIcon = 'fas fa-plus';
                    } else {
                        buttonLabel = 'deselect-title';
                        buttonStyle = 'btn-danger-outline';
                        buttonIcon = 'fas fa-times';
                    }

                    if (this.state.previouslySelectedTitles.findIndex(t => t.get('titleId') === title.get('id')) !== -1) {
                        buttonLabel = 'already-selected';
                        buttonStyle = 'btn-danger-outline disabled';
                        buttonIcon = '';
                    }
                    return `<button class="btn ${buttonStyle} add-title-panel" data-title-id="${title.get('id')}" data-title-label="${buttonLabel}">
                        <i class="${buttonIcon}""></i>&nbsp;${context.intl.messages[`events.titles.${buttonLabel}`]}</button>`;
                },
                onClick: this.handleAddTitle
            }
        ];

        let filterOptions;
        if (this.state.showFiltersPanel) {
            filterOptions = <FilterOptions
                filters={this.state.filters}
                isOutlineStyle
                location={this.props.location}
                showActive={true}
                showCategory={false}
                showCreateDate={false}
                showMpmNumber={false}
                showReferenceId={false}
                showReleaseDate={false}
                showTitle={false}
                showStatusFields={true}
            />;
        }

        return (
            <Preloader show={this.state.showPreloader} fixed loadingDots>
                <div>
                    <ListTitles
                        activePage={Math.ceil(offset/size) || 0}
                        columns={columns}
                        filterButton
                        isOutlineStyle
                        displayLinks={false}
                        linkTarget="_blank"
                        location={this.props.location}
                        onPageChange={this.handleLoadPage}
                        onSearchChange={this.handleSearchTerm}
                        onSortChange={this.handleSortChange}
                        sortDirection={this.state.sortDirection}
                        sortFieldName={this.state.sortFieldName}
                        tableId={'add-events-titles'}
                        titles={titles}
                        totalPages={Math.ceil(total/size)}
                    />
                    {filterOptions}
                </div>
            </Preloader>
        );
    }

}

export default Container.create(AddExistingTitles);
export {AddExistingTitles as AddExistingTitles_BASE};
