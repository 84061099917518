/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import ReactTable from 'react-table';

import Select from '~/src/common/base-select/base-select';
import {GetNoDataProps} from '~/src/common/react-table/react-table';
import {GetAttr} from '~/src/common/utils/utils';
import {EventActions} from '~/src/events/event-actions';
import EventStore from '~/src/events/event-store';

class NominationTable extends React.Component {

    static get propTypes() {
        return {
            columnNumber: PropTypes.number.isRequired,
            nominationList: PropTypes.instanceOf(Immutable.List),
            title: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            nominationList: Immutable.List(),
        };
    }

    constructor(props) {
        super(props);

        this.handleAddNewNomination = this.handleAddNewNomination.bind(this);
        this.handleCellInputOnChange = this.handleCellInputOnChange.bind(this);
        this.handleTrash = this.handleTrash.bind(this);
        this.renderTypeCell = this.renderTypeCell.bind(this);
        this.renderEditableDivCell = this.renderEditableDivCell.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
    }

    handleTrash(displayOrder) {
        EventActions.removeNomination(this.props.columnNumber, displayOrder);
        return;
    }

    handleCellInputOnChange(displayOrder, columnId, value) {
        EventActions.updateNomination(this.props.columnNumber, displayOrder, columnId, value);
        EventActions.clearEditingNomination();
    }

    handleCellOnClick(displayOrder, columnId) {
        // Only used for displayType column
        EventActions.clearEditingNomination();
        EventActions.updateNomination(this.props.columnNumber, displayOrder, 'editing', columnId);
    }

    handleAddNewNomination() {
        EventActions.addNewNomination(this.props.columnNumber);
    }

    renderEditableDivCell(cellInfo) {
        const columnId = cellInfo.column.id;
        const displayOrder = cellInfo.original.get('displayOrder');
        let classes = ['editable-cell'];

        return (
            <div
                className={ClassNames(classes)}
                contentEditable={true}
                suppressContentEditableWarning
                onBlur={(e) => {
                    this.handleCellInputOnChange(displayOrder, columnId, e.target.innerHTML);
                }}
                dangerouslySetInnerHTML={{
                    __html: cellInfo.original.get(columnId)
                }}
            />
        );
    }

    renderTypeCell(cellInfo) {
        // FIXME: Clicking outside select on page background/etc should remove editing state. onBlur doesn't fire properly.
        // FIXME: no way to tab into this cell from previous column when in edit mode
        const columnId = cellInfo.column.id;
        const displayOrder = cellInfo.original.get('displayOrder');
        let classes = ['editable-cell'];

        if (cellInfo.original.get('editing') === columnId) {
            // The styled div wrapper is needed to prevent the dropdown options hiding behind table
            // But this breaks on smaller resolutions by shifting dropdown far to the right
            return <div style={{minWidth: 150}}>
                <Select
                    disabled={false}
                    getOptionLabel={GetAttr('label')}
                    getOptionValue={GetAttr('id')}
                    onChange={(e) => {
                        this.handleCellInputOnChange(displayOrder, columnId, e.id);
                    }}
                    options="nominationTypes"
                    store={EventStore}
                    placeholder={cellInfo.original.get('displayType')}
                    value={cellInfo.original.get('displayType')}
                />
            </div>;
        }

        return <div className={ClassNames(classes)}>{cellInfo.original.get(columnId)}&nbsp;</div>;
    }

    handleReorder(e, row, position) {
        if (e.target.draggable === true) {
            let dragDiff = Math.round((parseInt(this.state.dragFrom, 10) - parseInt(position, 10)) / 50);
            dragDiff = -(dragDiff);
            const newPosition = row.index + dragDiff;
            EventActions.reorderNominationRow(this.props.columnNumber, row.index, newPosition);
        }
    }

    render() {
        /*istanbul ignore next*/
        const columns = [{
            accessor: 'displayValue',
            className: 'row-reorder-handle',
            Header:  () => (
                <strong>
                    {this.context.intl.messages['events.presentation.nomination-info.value']}
                </strong>
            ),
            Cell: this.renderEditableDivCell
        }, {
            accessor: 'displayType',
            maxWidth: 160,
            Header:  () => (
                <strong>
                    {this.context.intl.messages['events.presentation.nomination-info.type']}
                </strong>
            ),
            Cell: this.renderTypeCell
        }, {
            accessor: '_trash',
            headerClassName: 'h-alignment',
            maxWidth: 50,
            Header:  () => (
                <div className="text-center">
                    <strong><i className="far fa-trash" /></strong>
                </div>
            ),
            Cell: c => (
                <span>
                    <Button
                        className="btn btn-danger-outline"
                        onClick={this.handleTrash.bind(this, c.original.get('displayOrder'))}
                    >
                        <i className="fas fa-trash" />
                    </Button>
                </span>
            )
        }];

        return (
            <div className="event-presentation">
                <div>
                    <h3 style={{marginBottom: 0}}>
                        {this.context.intl.messages[this.props.title]}
                        <div className="pull-right">
                            <Button className="btn btn-primary-outline btn-bulk" onClick={this.handleAddNewNomination}>
                                <i className="fas fa-plus" />{this.context.intl.messages['events.presentation.nomination-info.add-item']}
                            </Button>
                        </div>
                    </h3>
                    <p><small><em>{this.context.intl.messages['events.presentation.nomination-info.click-on-a-cell']}</em></small></p>
                    <div>
                        <div>
                            <ReactTable
                                className="-striped table-bordered table-striped responsive MB10P"
                                columns={columns}
                                data={this.props.nominationList}
                                getTableProps={/*istanbul ignore next*/() => {
                                    // Fix for dropdown option list zIndex/Scroll bug
                                    return {
                                        style: {
                                            overflow: 'visible'
                                        }
                                    };
                                }}
                                getTbodyProps={/*istanbul ignore next*/() => {
                                    // Fix for dropdown option list zIndex/Scroll bug
                                    return {
                                        style: {
                                            overflow: 'visible'
                                        }
                                    };
                                }}
                                getTdProps={/*istanbul ignore next*/(state, rowInfo, column) => {
                                    const isDraggable = column.className.indexOf('row-reorder-handle') !== -1;
                                    return {
                                        draggable: isDraggable,
                                        onClick: () => {
                                            const ignore = ['_select', '_trash'];
                                            if (!ignore.includes(column.id) && rowInfo.original.get('editing') !== column.id) {
                                                // Enable editing mode for displayType cell only
                                                this.handleCellOnClick(rowInfo.original.get('displayOrder'), column.id);
                                            }
                                        },
                                        onDragEnd: e => this.handleReorder(e, rowInfo, e.screenY),
                                        onDragStart: e => {
                                            e.dataTransfer.setData('application/node type', this);
                                            this.setState({dragFrom: e.screenY});
                                        }
                                    };
                                }}
                                getNoDataProps={GetNoDataProps(this.props.nominationList.size)}
                                id={'title-presentation-nomination-list-table-id'}
                                loading={false}
                                showPagination={false}
                                sortable={false}
                                resizable={false}
                                pageSize={this.props.nominationList.size}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NominationTable;
