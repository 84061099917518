/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {ClientRepGroupActions} from '../../security/client-rep-group/client-rep-group-actions';
import ClientRepGroupStore from '../../security/client-rep-group/client-rep-group-store';


require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-responsive-bs');

class ClientRepGroupTable extends Component {
    static get propTypes() {
        return {
            clientRepGroups: PropTypes.instanceOf(Immutable.List).isRequired,
            clientRepGroupsCount: PropTypes.func,
            displayLinks: PropTypes.bool,
            filters: PropTypes.string,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            clientRepGroupsCount: undefined,
            filters: '',
            displayLinks: false
        };
    }

    static calculateState() {
        let state = ClientRepGroupStore.getState();
        let total = state.get('total');
        let size = state.get('size');
        let offset = state.get('offset');
        let activePage = 1;
        if (size !== 0) {
            activePage = Math.ceil(offset/size);
        }

        return {
            offset: offset,
            clientRepGroups: state.get('clientRepGroups'),
            size: size,
            total: total,
            totalPages: Math.ceil(total/20),
            activePage: activePage
        };
    }

    static getStores() {
        return [ClientRepGroupStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#clientRepGroup-table').DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets: -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }, {
                targets: 2,
                type: 'date'
            }, {
                targets: 3,
                type: 'date'
            }],
            iDisplayLength: 20,
            info: false,
            lengthChange: false,
            ordering: true,
            paging: true,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);

        // TODO: this is kind of cheating... maybe move the partners.forEach
        // to the render method?
        this.componentWillUpdate(this.props, this.state);
        return;

    }

    componentWillUpdate(nextProps/*, state*/) {
        this.$table.clear();

        let filterRegExp = new RegExp(nextProps.filters, 'i');
        let clientRepGroups = nextProps.clientRepGroups.filter(clientRepGroup => {
            let keep = clientRepGroup;
            if (keep && nextProps.filters) {
                keep = clientRepGroup.get('name').match(filterRegExp);
            }
            return keep;
        });

        this.props.clientRepGroupsCount(clientRepGroups.size);

        // Add data to the jQuery datatable.
        clientRepGroups.forEach((clientRepGroup) => {

            let created = Moment(clientRepGroup.get('createdDate'));
            if (created.isValid()) {
                created = created.format(this.context.intl.messages['date-format']);
            } else {
                created = '-';
            }

            let updated = Moment(clientRepGroup.get('updatedDate'));
            if (updated.isValid()) {
                updated = updated.format(this.context.intl.messages['date-format']);
            } else {
                updated = '-';
            }

            let clientRepGroupName = clientRepGroup.get('name');
            if (nextProps.displayLinks) {
                clientRepGroupName = `<a href="/groups/client-rep-groups/${clientRepGroup.get('id')}" class="edit-clientRepGroup">${clientRepGroup.get('name')}</a>`;
            }

            let clientRepGroupTerritories = clientRepGroup.get('territories').map(function(territory) {
                return territory.get('name');
            }).join(', ');

            this.$table.row.add([
                clientRepGroupName,
                clientRepGroupTerritories,
                created,
                updated,
                ''
            ]);

            return;
        });

        this.$table.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        // Remove global listeners.
        window.removeEventListener('resize', this.handleResize);
    }

    handleLoadPage(pageNumber) {
        ClientRepGroupActions.get(pageNumber * this.state.size, this.state.size);
        return;
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$table.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        // Handle click on an autorized partner's name.
        case !!~event.target.className.indexOf('edit-clientRepGroup'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }

        return;
    }

    render() {
        return (
            <div>
                <table id="clientRepGroup-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className="sorting" style={{minWidth: 320}}>Client Rep Group Name</th>
                            <th className="sorting">Territories</th>
                            <th className="sorting" style={{minWidth: 100}}>Created</th>
                            <th className="sorting" style={{minWidth: 100}}>Updated</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Container.create(ClientRepGroupTable);
