/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';

import {ConfigurationActions} from './configuration-actions';

import {FormSection, FormRow, FormItem} from '~/src/common/form/form';

class OptionsTab extends Component {

    static get propTypes() {
        return {
            configuration: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        const buildCheckbox = (attr) => {
            const model = this.props.configuration.get(attr);
            /*istanbul ignore next*/
            if (model) {
                return <FormItem
                    attr="active"
                    label={attr}
                    disabled={false}
                    model={model}
                    setter={/*istanbul ignore next*/(a, v) => ConfigurationActions.update_attribute(attr, a, v)}
                    type="checkbox"
                />;
            }
        };

        // Section: Other Feature Flags (all remaining/uncategorized options will appear here automatically when returned by api)
        const otherFeatureKeys = Object.keys(this.props.configuration.toJS()).filter(/*istanbul ignore next*/(v) => {
            return !v.endsWith('forensic.handling');
        })?.sort();

        let otherFeatureGroups = [[], [], []];
        let otherFeatureGroupNum = 0;
        otherFeatureKeys.forEach(/*istanbul ignore next*/k => {
            if (otherFeatureGroups[otherFeatureGroupNum].length > (otherFeatureKeys.length/3)) {
                otherFeatureGroupNum++;
            }
            otherFeatureGroups[otherFeatureGroupNum].push(k);
        });

        const sectionOtherFeatures = (
            <FormRow>
                <div className="col-md-4">
                    {otherFeatureGroups[0].map(/*istanbul ignore next*/k => buildCheckbox(k))}
                </div>
                <div className="col-md-4">
                    {otherFeatureGroups[1].map(/*istanbul ignore next*/k => buildCheckbox(k))}
                </div>
                <div className="col-md-4">
                    {otherFeatureGroups[2].map(/*istanbul ignore next*/k => buildCheckbox(k))}
                </div>
            </FormRow>
        );

        // Section: Forensic Handling
        let forensicHandlingKeys = Object.keys(this.props.configuration.toJS()).filter(/*istanbul ignore next*/(v) => {
            return v.endsWith('forensic.handling');
        })?.sort();

        let forensicHandlingGroups = [[], [], []];
        let forensicHandlingGroupNum = 0;
        forensicHandlingKeys.forEach(/*istanbul ignore next*/k => {
            if (forensicHandlingGroups[forensicHandlingGroupNum].length > (forensicHandlingKeys.length/3)) {
                forensicHandlingGroupNum++;
            }
            forensicHandlingGroups[forensicHandlingGroupNum].push(k);
        });

        const sectionForensicHandling = (
            <FormRow>
                <div className="col-md-4">
                    {forensicHandlingGroups[0].map(/*istanbul ignore next*/k => buildCheckbox(k))}
                </div>
                <div className="col-md-4">
                    {forensicHandlingGroups[1].map(/*istanbul ignore next*/k => buildCheckbox(k))}
                </div>
                <div className="col-md-4">
                    {forensicHandlingGroups[2].map(/*istanbul ignore next*/k => buildCheckbox(k))}
                </div>
            </FormRow>
        );

        return (
            <div>
                <FormSection>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.setup.configuration.options.other-feature-flags']}</ControlLabel>
                        {sectionOtherFeatures}
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['hardac.setup.configuration.options.forensic-handling']}</ControlLabel>
                        {sectionForensicHandling}
                    </FormGroup>

                </FormSection>
            </div>
        );
    }
}

export default OptionsTab;
