/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {GuildConstants} from './guild-actions';
import Validations, {SEVERITY} from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';
import {ActionHistoryConstants} from '../system/action-history/action-history-actions';

/*istanbul ignore next*/
const GuildValidations = {
    name: {label: 'guild.summary.name', validations: [Validations.required, Validations.max(50)]},
    partnerId: {label: 'guild.summary.partner.label', validations: [Validations.requiredIf(() => store.getState().getIn(['guild', 'anonymousSignUp'])), Validations.max(50)]},
    emailTemplateName: {label: 'guild.summary.email', validations: [Validations.requiredIf(() => store.getState().getIn(['guild', 'anonymousSignUp'])), Validations.max(50)]},
    permissionPackageId: {label: 'guild.summary.permission_package.label', validations: [Validations.required]},
    validStartDate: {label: 'guild.summary.dates.start',
        validations: [
            Validations.required,
            Validations.date,
            Validations.custom(
                () => {
                    return 'Start date cannot be before today.';
                },
                () => {
                    let isValid = true;
                    const guildId = store.getState().getIn(['guild']).toJS()?.id;

                    /*  If on edit, enable to have previous date before today.
                        On New enabling to do 30 minutes before current hour for testing purposes. (60000 is a minute) */
                    if (!guildId) {
                        if (new Date(store.getState().getIn(['guild', 'validStartDate'])).getTime() < (new Date().getTime() - (60000 * 30))) {
                            isValid = false;
                        }
                    }

                    return isValid;
                },
            )
        ]
    },
    validEndDate: {label: 'guild.summary.dates.end',
        validations: [
            Validations.required,
            Validations.date,
            Validations.custom(
                () => {
                    return 'End date cannot be set before Start date.';
                },
                () => {
                    const startDate = store.getState().getIn(['guild', 'validStartDate']);
                    const endDate = store.getState().getIn(['guild', 'validEndDate']);
                    let isValid = true;

                    if (!!startDate && !!endDate) {
                        if (new Date(startDate) > new Date(endDate)) {
                            isValid = false;
                        }
                    }

                    return isValid;
                },
            )
        ]}
};

/*istanbul ignore next*/
const GuildDateRangeValidations = {
    updateDateRange: {
        validations: [
            Validations.custom(
                () => {
                    return 'Start/End Dates have been changed, click on "Sync All Accounts" button to apply to all accounts in this guild.';
                },
                () => {
                    let isValid = true;
                    if (store.getState().get('total') > 0 &&
                        !store.getState().get('syncAllAccounts') &&
                        (store.getState().getIn(['guild', 'validStartDate']) !== store.getState().getIn(['originalGuild', 'validStartDate']) ||
                        store.getState().getIn(['guild', 'validEndDate']) !== store.getState().getIn(['originalGuild', 'validEndDate']))) {
                        isValid = false;
                    }
                    return isValid;
                },
                SEVERITY.WARNING
            )
        ]
    }
};

class GuildStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            guild: Immutable.fromJS({
                displayTimezone: 'America/Los_Angeles'
            }),
            guilds: Immutable.fromJS([]),
            guildsAccountsSpreadsheet: Immutable.fromJS({
                blob: {}
            }),
            history: Immutable.List(),
            offset: 0,
            showPreloader: false,
            size: 15,
            syncAllAccounts: false,
            total: 0,
            users: Immutable.fromJS([]),
        });
    }

    getValidations() {
        return Validations.validate(this.getState().get('guild'), GuildValidations);
    }

    getDateRangeValidations() {
        return Validations.validate(this.getState().get('guild'), GuildDateRangeValidations);
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.GUILD ) {
                state = state.updateIn(['history'], history => history.unshift(action.note));
            }
            break;

        case GuildConstants.CLEAR:
            state = this.getInitialState();
            break;

        case GuildConstants.GUILD.DEACTIVATE.ERROR:
        case GuildConstants.GUILD.FIND_BY_ID.ERROR:
        case GuildConstants.GUILD.GET.ERROR:
        case GuildConstants.GUILD.RESET.ERROR:
        case GuildConstants.GUILD.SAVE.ERROR:
        case GuildConstants.GUILD.SYNC_ALL_ACCOUNTS.ERROR:
        case GuildConstants.GUILD.USER.BULK_ADD.ERROR:
        case GuildConstants.GUILD.USER.CHILD_ACCOUNTS_ADD.ERROR:
        case GuildConstants.GUILD.USER.GET.ERROR:
            state = state.merge({showPreloader: false});
            break;

        case GuildConstants.GUILD.DEACTIVATE.START:
        case GuildConstants.GUILD.FIND_BY_ID.START:
        case GuildConstants.GUILD.GET.START:
        case GuildConstants.GUILD.RESET.START:
        case GuildConstants.GUILD.SAVE.START:
        case GuildConstants.GUILD.SYNC_ALL_ACCOUNTS.START:
        case GuildConstants.GUILD.USER.BULK_ADD.START:
        case GuildConstants.GUILD.USER.CHILD_ACCOUNTS_ADD.START:
        case GuildConstants.GUILD.USER.GET.START:
            state = state.merge({showPreloader: true});
            break;

        case GuildConstants.GUILD.RESET.SUCCESS:
        case GuildConstants.GUILD.DEACTIVATE.SUCCESS:
        case GuildConstants.GUILD.SAVE.SUCCESS:
            state = state.merge({
                guild: action.guild,
                originalGuild: action.guild,
                showPreloader: false,
            });
            break;

        case GuildConstants.GUILD.FIND_BY_ID.SUCCESS:
            state = state.merge({
                guild: action.guild,
                originalGuild: action.guild,
                showPreloader: false,
                history: action.history,
            });
            break;

        case GuildConstants.GUILD.GET.SUCCESS:
            state = state.merge({
                guilds: action.guilds,
                offset: action.offset,
                showPreloader: false,
                size: action.size,
                total: action.total,
            });
            break;

        case GuildConstants.GUILD.SYNC_ALL_ACCOUNTS.SUCCESS:
            state = state.merge({
                showPreloader: false,
                syncAllAccounts: true
            });
            break;

        case GuildConstants.GUILD.UPDATE:
            state = state.setIn(['guild', ...action.attr.split('.')], action.value);
            break;

        case GuildConstants.GUILD.USER.BULK_ADD.SUCCESS:
        case GuildConstants.GUILD.USER.CHILD_ACCOUNTS_ADD.SUCCESS:
            state = state.merge({showPreloader: false});
            break;

        case GuildConstants.GUILD.USER.GET.SUCCESS:
            state = state.merge({
                users: action.users,
                offset: action.offset,
                showPreloader: false,
                size: action.size,
                total: action.total,
            });
            break;
        }
        return state;
    }
}

const store = new GuildStore(Dispatcher);

export default store;

export {GuildValidations, GuildDateRangeValidations};
