/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import ListTitles from './list-titles';

import EventStore from '~/src/events//event-store';
import {EventActions} from '~/src/events/event-actions';
import Preloader from '~/src/preloader';
import {RouterActions} from '~/src/router/router-actions';

class PresentationTable extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static get propTypes() {
        return {
            disabled: PropTypes.bool.isRequired,
            event: PropTypes.instanceOf(Immutable.Map).isRequired,
            location: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        const eventState = EventStore.getState();
        return {
            allAssets : eventState.getIn(['event', 'assets']),
            allTitles : eventState.getIn(['event', 'titles']),
            originalEvent: eventState.get('originalEvent'),
            showPreloader: eventState.get('showPreloader'),
            titles: eventState.get('titlesPresentation'),
        };
    }

    static getStores() {
        return [EventStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.constructor.calculateState());

    }

    componentDidMount() {
        EventActions.getPresentationTitles(this.state.allTitles, this.state.allAssets);

        RouterActions.registerRedirectCheck(/*istanbul ignore next*/(location) => {
            return this.isDirty(location);
        });
        return;
    }

    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* events */
                goingTo[2] === current[2] /* events id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }

        return !this.props.event.equals(this.state.originalEvent);
    }

    render() {
        return (
            <Preloader show={this.state.showPreloader} fixed loadingDots>
                <div>
                    <ListTitles
                        activePage={1}
                        columns={['rowIndex', 'backgroundImage', 'titleThumbnail', 'name', 'videos', 'edit']}
                        disabled={this.props.disabled}
                        displayLinks
                        onPageChange={/*istanbul ignore next*/() => void 0}
                        onSelect={/*istanbul ignore next*/() => void 0}
                        selectAll={false}
                        sortDirection={'desc'}
                        sortFieldName={'updatedDate'}
                        titles={this.state.titles}
                        totalPages={1}
                    />
                </div>
            </Preloader>
        );
    }
}

export default Container.create(PresentationTable);
