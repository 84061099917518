/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {NotificationActions} from '~/src/common/notification/notification-actions';
import Dispatcher from '~/src/dispatcher/dispatcher';
import Request from '~/src/request';

const CONSTANTS = {
    CONFIGURATION: {
        CLEAR: 'hardac-configuration.clear',
        GET: {
            ERROR: 'hardac-configuration.get.error',
            START: 'hardac-configuration.get.start',
            SUCCESS: 'hardac-configuration.get.success'
        },
        SAVE: {
            ERROR: 'hardac-configuration.save.error',
            START: 'hardac-configuration.save.start',
            SUCCESS: 'hardac-configuration.save.success'
        },
        UPDATE_ATTRIBUTE: 'hardac-configuration.update.attribute'
    },

    toArray: function(constant) {
        return Object.keys(this[constant]).map(k => this[constant][k]);
    }
};

class ConfigurationActions {
    get() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CONFIGURATION.GET.START
        });

        Request.get('system/feature-control')
            .exec()
            .then(response => {
                let config = {};
                response.body.forEach((c) => {
                    config[c.name] = Object.assign({}, c);
                });

                Dispatcher.dispatch({
                    actionType: CONSTANTS.CONFIGURATION.GET.SUCCESS,
                    configuration: Immutable.fromJS(config)
                });
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.CONFIGURATION.GET.ERROR
                });

                switch (err.status) {
                case 404:
                    NotificationActions.showAlertDanger('hardac.setup.configuration.get.forbidden');
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.setup.configuration.get.error');
                    break;
                }

                throw err;
            });

        return;
    }

    getConfigurationChanges(newConfiguration, originalConfiguration) {
        let changes = [];
        Object.keys(newConfiguration.toJS()).forEach(c => {
            let v = newConfiguration.get(c).toJS();

            if (v.active !== originalConfiguration.getIn([c, 'active'])) {
                changes.push(v);
            }
        });

        return changes;
    }

    save(newConfiguration, originalConfiguration) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CONFIGURATION.SAVE.START,
        });

        const data = this.getConfigurationChanges(newConfiguration, originalConfiguration);

        const requests = data.map(c => {
            return Request.put(`system/feature-control/${c.name}`).send(c).exec();
        });

        Promise.all(requests).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.CONFIGURATION.SAVE.SUCCESS,
            });

            NotificationActions.showAlertSuccess('hardac.setup.configuration.save.success');
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.CONFIGURATION.SAVE.ERROR,
            });

            switch (err.status) {
            case 404:
                NotificationActions.showAlertDanger('hardac.setup.configuration.save.forbidden');
                break;
            default:
                NotificationActions.showAlertDanger('hardac.setup.configuration.save.error');
                break;
            }

            throw err;
        });

        return;
    }

    update_attribute(attr, field, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CONFIGURATION.UPDATE_ATTRIBUTE,
            attr,
            field,
            value
        });

        return;
    }
}

const actions = new ConfigurationActions();

export {
    actions as ConfigurationActions,
    CONSTANTS as ConfigurationConstants
};
