/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import AppStylePreviewModal from '~/src/common/app-styles/app-style-preview-modal';
import {FormRow} from '~/src/common/form/form';
import {CreateFile} from '~/src/common/utils/utils';
import {EventActions, EventConstants} from '~/src/events/event-actions';

import '~/src/common/app-styles/app-style.less';

class TitleThumbnail extends React.Component {

    static get propTypes() {
        return {
            selectedTitleThumbnailOption: PropTypes.string,
            titleStyle: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleStyleFiles: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            selectedTitleThumbnailOption: null,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            showAppStylePreviewModal: false
        };

        this.handleAppStylePreviewModalHide = this.handleAppStylePreviewModalHide.bind(this);
        this.handleAppStylePreviewModalRemove = this.handleAppStylePreviewModalRemove.bind(this);
        this.handleEditAssetClick = this.handleEditAssetClick.bind(this);
        this.handleFileInputChange = this.handleFileInputChange.bind(this);
        this.handleOpenFileInput = this.handleOpenFileInput.bind(this);
        this.handleSelectModifyOption = this.handleSelectModifyOption.bind(this);
    }

    handleAppStylePreviewModalHide() {
        this.setState(() => ({
            showAppStylePreviewModal: false
        }));

        return;
    }

    handleAppStylePreviewModalRemove() {
        // Remove the url from the title object so that the file
        // is removed from the screen.
        EventActions.updateTitle('thumbnailImageUrl', undefined);
        EventActions.updateTitleStyle('remove.thumbnailImage', true);
        this.handleAppStylePreviewModalHide();
        return;
    }

    handleSelectModifyOption(selectedOption) {
        EventActions.setModifyOption('selectedTitleThumbnailOption', selectedOption);
    }

    /*istanbul ignore next*/
    handleOpenFileInput() {
        this.refs.thumbnailImage.click();
        return;
    }

    /*istanbul ignore next*/
    handleFileInputChange() {
        if (
            this.refs.thumbnailImage.files &&
            this.refs.thumbnailImage.files[0]
        ) {
            let file = this.refs.thumbnailImage.files[0];
            //for custom background image, background video and title treatment
            file = CreateFile(file);
            EventActions.updateTitleStyle('thumbnailImage', file);
        }
    }

    handleEditAssetClick() {
        this.setState(() => ({
            showAppStylePreviewModal: true
        }));

        return;
    }

    render() {
        let inputStyle = {
            display: 'none'
        };

        let chooseThumbnailImage = (
            <div>
                <Button
                    bsStyle="default"
                    onClick={this.handleOpenFileInput}
                >
                    <i className="far fa-image"></i>
                    &nbsp;
                    {this.context.intl.messages['titles.create.style.background-image-choose']}
                </Button>
                <em>{this.props.titleStyleFiles.get('thumbnailImage').name}</em>
                <input
                    accept="image/*"
                    onChange={this.handleFileInputChange}
                    ref="thumbnailImage"
                    style={inputStyle}
                    type="file"
                />
            </div>
        );
        if (this.props.titleStyle.get('thumbnailImageUrl')) {
            chooseThumbnailImage = (
                <div className="thumbnail-image-container">
                    <img
                        className="img-responsive"
                        src={this.props.titleStyle.get('thumbnailImageUrl')}
                    />
                    <div className="button-container">
                        <div className="button-container-2">
                            <Button
                                bsStyle="default"
                                onClick={this.handleEditAssetClick}
                            >
                                <i className="fas fa-edit"></i>
                                &nbsp;
                                {this.context.intl.messages['titles.create.style.background-image-update']}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        let appStylePreviewModalContent = (
            <img
                className="img-responsive"
                src={this.props.titleStyle.get('thumbnailImageUrl')}
            />
        );

        const options = EventConstants.TITLE_PRESENTATION.MODIFY_OPTIONS;
        return (
            <div className="event-presentation title-app-style">
                <h3><i className="fa-regular fa-image-portrait"></i>&nbsp;{this.context.intl.messages['events.presentation.title-thumbnail']}</h3>
                <p>
                    <small>
                        <em>
                            {this.context.intl.messages['events.presentation.title-thumbnail.description']}
                        </em>
                    </small>
                </p>
                <div>
                    <div style={{display: 'flex'}}>
                        <span style={{display: 'flex', alignItems: 'center', gap: 6}}>
                            <input
                                checked={this.props.selectedTitleThumbnailOption === options.INHERIT}
                                id="inherit-title-thumbnail"
                                name="inherit-title-thumbnail"
                                onClick={this.handleSelectModifyOption.bind(this, options.INHERIT)}
                                type="radio"
                            />
                            <span><i className="fa-solid fa-link-horizontal"></i>&nbsp;&nbsp;{this.context.intl.messages['events.presentation.modify-option.inherit']}</span>
                        </span>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <span style={{display: 'flex', alignItems: 'center', gap: 6}}>
                            <input
                                checked={this.props.selectedTitleThumbnailOption === options.CUSTOM}
                                id="custom-title-thumbnail"
                                name="custom-title-thumbnail"
                                onClick={this.handleSelectModifyOption.bind(this, options.CUSTOM)}
                                type="radio"
                            />
                            <span><i className="fa-solid fa-pen-to-square"></i>&nbsp;&nbsp;{this.context.intl.messages['events.presentation.modify-option.custom']}</span>
                        </span>
                    </div>
                    <br />
                    {this.props.selectedTitleThumbnailOption === options.CUSTOM && (
                        <div>
                            <span className="text-yellow"><i className="fas fa-exclamation-triangle" />&nbsp;<b>{this.context.intl.messages['events.presentation.title-style.inherit-warning']}</b></span>
                            <br />
                            <br />
                            <FormRow>
                                {chooseThumbnailImage}
                            </FormRow>
                            <AppStylePreviewModal
                                onHide={this.handleAppStylePreviewModalHide}
                                onRemove={this.handleAppStylePreviewModalRemove}
                                show={this.state.showAppStylePreviewModal}
                                bgColor={this.props.titleStyle.get('appBackgroundColor')}
                            >
                                {appStylePreviewModalContent}
                            </AppStylePreviewModal>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default TitleThumbnail;
