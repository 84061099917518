/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';

import Table from '../../browse/table';
import {EventConstants, EventActions} from '../../event-actions';
import EventStore from '../../event-store';

import SearchBox from '~/src/common/search-box/search-box';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {Debounce} from '~/src/common/utils/utils';
import Preloader from '~/src/preloader';
import {RouterActions} from '~/src/router/router-actions';

class EventsTab extends React.Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        const eventState = EventStore.getState();
        return {
            fycEvents: eventState.get('fycEvents'), // FIXME mellodev remove this
            event: eventState.get('event'),
            events: eventState.getIn(['fycEvents', 'events']),
            numberOfPages: Math.ceil(eventState.getIn(['fycEvents', 'total']) / EventConstants.EVENT.TITLES.PAGE_SIZE),
            originalEvent: eventState.get('originalEvent'),
            showPreloader: eventState.getIn(['fycEvents', 'showPreloader']),
            total: eventState.getIn(['fycEvents', 'total']),
        };
    }

    static getStores() {
        return [EventStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            currentPage: 0,
            selectAll: false,
            toRemoveIds: []
        }, this.constructor.calculateState());

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.init = this.init.bind(this);
    }

    componentDidMount() {
        RouterActions.registerRedirectCheck(/*istanbul ignore next*/(location) => {
            return this.isDirty(location);
        });

        this.init();
        return;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.query !== nextProps.location.query) {
            this.init(nextProps);
        }
        return;
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('name', term).clearRouteState('offset').apply();
    }

    init(props) {
        // Prevent double dispatch when F5 from events tab
        setTimeout(() => {
            /*istanbul ignore next */
            const routeState = this.getRouteState(props || this.props);
            EventActions.getFYCEventsByRootEventId(this.state.event.get('eventId'), routeState);
        }, 0);
    }


    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* events */
                goingTo[2] === current[2] /* events id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }

        return !this.state.originalEvent.equals(this.state.event);
    }

    render() {
        return (
            <Preloader show={this.state.showPreloader} fixed loadingDots>
                <div>
                    <h3><i className="far fa-calendar-star"></i> {this.context.intl.messages['events.events-tab.child-events']} <small>{this.state.total}</small></h3>
                    <hr />
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6 show-grid">
                                <SearchBox value={''} onChange={this.handleSearchTerm}/>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box">
                                    <div className="box-body">
                                        <Table
                                            displayLinks={true}
                                            activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                            events={this.state.events}
                                            onPageChange={this.handleLoadPage}
                                            totalPages={Math.ceil(this.state.total/20) || 0}
                                            linkTarget="_blank"
                                            showEventTypeColumn={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Preloader>
        );
    }
}

export default WithStoreOnRoute(Container.create(EventsTab));
